import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import Page404 from "../views/Page404.vue";
import Page403 from "../views/Page403.vue";
import Login from "../views/Login.vue";
import Auth from "../views/Auth.vue";
import ContactPage from "../views/contact/Index.vue";
import { authMiddleware, roleMiddleware } from "./middlewares";
import { legacyScopeRedirect } from "./redirects";
import {
  COMMON_SCOPE,
  EDUCATION_SCOPE,
  REQUESTS_SCOPE,
  PROJECTS_SCOPE,
  ACHIEVEMENTS_SCOPE,
  ORGANIZATIONS_SCOPE,
  MENTORS_SCOPE,
  USER_CONTACTS_SCOPE,
  ORG_COMMON_ROUTE,
  ORG_ENTITY_ROUTE,
  ORG_ADMINS_ROUTE,
  ORG_GROUPS_ROUTE,
  ORG_REQUESTS_ROUTE,
  ORG_VENUES_ROUTE,
  ORG_PARTICIPANTS_ROUTE,
} from "../constants";

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "/",
    name: "boards",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/boards/Index.vue"),
  },
  {
    path: "/boards/new",
    name: "boards_create",
    component: () =>
      import(/* webpackChunkName: "boards" */ "../views/boards/Create.vue"),
  },
  {
    path: "/boards/:id",
    name: "board",
    component: () =>
      import(/* webpackChunkName: "boards" */ "../views/boards/_id.vue"),
  },

  {
    path: "/boards/:id/edit",
    name: "boards_update",
    component: () =>
      import(/* webpackChunkName: "boards" */ "../views/boards/Update.vue"),
  },
  {
    path: "/tags",
    name: "tags",
    component: () => import(/* webpackChunkName: "tags" */ "../views/Tags.vue"),
  },
  {
    path: "/tags/import",
    name: "tags_import",
    meta: {
      roles: ["superuser"],
    },
    component: () =>
      import(/* webpackChunkName: "tags" */ "../views/TagsImport.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: {
      roles: ["superuser"],
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Search.vue"),
  },
  {
    path: "/contacts/:id",
    component: ContactPage,
    beforeEnter: legacyScopeRedirect,
    children: [
      {
        path: "",
        name: COMMON_SCOPE,
        component: () =>
          import(
            /* webpackChunkName: "common" */ "../views/contact/Common.vue"
          ),
      },
      {
        path: USER_CONTACTS_SCOPE,
        name: USER_CONTACTS_SCOPE,
        component: () =>
          import(
            /* webpackChunkName: "communications" */ "../views/contact/Communications.vue"
          ),
      },
      {
        path: REQUESTS_SCOPE,
        name: REQUESTS_SCOPE,
        component: () =>
          import(
            /* webpackChunkName: "requests" */ "../views/contact/Requests.vue"
          ),
      },
      {
        path: PROJECTS_SCOPE,
        name: PROJECTS_SCOPE,
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "../views/contact/Projects.vue"
          ),
      },
      {
        path: ACHIEVEMENTS_SCOPE,
        name: ACHIEVEMENTS_SCOPE,
        component: () =>
          import(
            /* webpackChunkName: "achievements" */ "../views/contact/Achievements.vue"
          ),
      },
      {
        path: EDUCATION_SCOPE,
        name: EDUCATION_SCOPE,
        component: () =>
          import(
            /* webpackChunkName: "education" */ "../views/contact/Educations.vue"
          ),
      },
      {
        path: ORGANIZATIONS_SCOPE,
        name: ORGANIZATIONS_SCOPE,
        component: () =>
          import(
            /* webpackChunkName: "organizations" */ "../views/contact/Organizations.vue"
          ),
      },
      {
        path: MENTORS_SCOPE,
        name: MENTORS_SCOPE,
        component: () =>
          import(
            /* webpackChunkName: "mentors" */ "../views/contact/Mentors.vue"
          ),
      },
    ],
  },
  {
    path: "/social_sync",
    name: "social_sync",
    component: () =>
      import(/* webpackChunkName: "socialSync" */ "../views/SocialSync.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      authless: true,
    },
  },
  {
    path: "/auth/complete",
    name: "auth",
    component: Auth,
    meta: {
      authless: true,
    },
  },
  {
    path: "/organizations",
    name: "talent_organizations",
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ "../views/organizations/Index.vue"
      ),
  },
  {
    path: "/organizations/:orgId",
    name: ORG_COMMON_ROUTE,
    component: () =>
      import(
        /* webpackChunkName: "organization" */ "../views/organizations/organization/Index.vue"
      ),
    children: [
      {
        path: "",
        name: ORG_COMMON_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "commonOrganization" */ "../views/organizations/organization/Organization.vue"
          ),
      },
      {
        path: ORG_ENTITY_ROUTE,
        name: ORG_ENTITY_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "entityOrganization" */ "../views/organizations/organization/Entity.vue"
          ),
      },
      {
        path: ORG_ADMINS_ROUTE,
        name: ORG_ADMINS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "adminsOrganization" */ "../views/organizations/organization/Admins.vue"
          ),
      },
      {
        path: ORG_PARTICIPANTS_ROUTE,
        name: ORG_PARTICIPANTS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "participantsOrganization" */ "../views/organizations/organization/Participants.vue"
          ),
      },
      {
        path: ORG_GROUPS_ROUTE,
        name: ORG_GROUPS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "groupsOrganization" */ "../views/organizations/organization/Groups.vue"
          ),
      },
      {
        path: ORG_REQUESTS_ROUTE,
        name: ORG_REQUESTS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "requestsOrganization" */ "../views/organizations/organization/OrganizationsRequests.vue"
          ),
      },
      {
        path: ORG_VENUES_ROUTE,
        name: ORG_VENUES_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "venuesOrganization" */ "../views/organizations/organization/Venues.vue"
          ),
      },
    ],
  },
  {
    path: "/403",
    name: "403",
    component: Page403,
    meta: {
      authless: true,
    },
  },
  {
    path: "*",
    name: "404",
    component: Page404,
    meta: {
      authless: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach(authMiddleware);
router.beforeEach(roleMiddleware);

export default router;
