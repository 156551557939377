import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/global-components";
import "./plugins/sentry";
import "./plugins/toast";
import "./plugins/vee-validate";
// import "@babel/polyfill";
import errorToast from "@/mixins/errorToast";
Vue.config.productionTip = false;
Vue.mixin(errorToast);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/serviceWorker.js")
    .then(() => console.log("Service Worker зарегистрирован"))
    .catch((err) => console.error("Ошибка регистрации Service Worker:", err));
}
