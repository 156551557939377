export const ACCESS_TOKEN = "access_token";
export const MAX_TAG_NAME = 30;
export const MIN_TAG_NAME = 2;
export const DEFAULT_TAG_COLOR = "#757575";
export const MIN_STRING_LENGTH = 3;
export const MAX_SHORT_STRING_LENGTH = 50;
export const MAX_LONG_STRING_LENGTH = 250;
/**
 * Статусы файла согласия
 */
export const ADULT_CONFIRM_STATUS_NONE = "";
export const ADULT_CONFIRM_STATUS_MODERATION = "u";
export const ADULT_CONFIRM_STATUS_ACCEPTED = "a";
export const ADULT_CONFIRM_STATUS_REJECTED = "r";

/**
 * Статусы заявки на мероприятия
 */
export const REQUEST_STATUS_REJECTED = -2; // Отклонена пользователем
export const REQUEST_STATUS_AWAIT = 0; // Ожидает модерации
export const REQUEST_STATUS_CANCELED = -1; // Отклонена модератором
export const REQUEST_STATUS_CONFIRMED = 1; // Согласована модератором
/**
 * Дефолтный формат отображения даты на клиенте
 */
export const DATE_DISPLAY_FORMAT = "DD.MM.YYYY";
/**
 * Дефолтный формат хранения даты на сервере
 */
export const DATE_DATABASE_FORMAT = "YYYY-MM-DD";
/**
 * Уровни реализации проекта
 */
export const PROJECT_LEVEL_DEMO = "d";
export const PROJECT_LEVEL_PROTO = "p";
export const PROJECT_LEVEL_IMP = "i";

export const TYPE_FILE_LABEL = "file";
export const TYPE_LINK_LABEL = "link";
export const TYPE_STRING_LABEL = "string";
export const TYPE_CUSTOM_LABEL = "custom";

export const FIELD_TYPE_FILE = "f";
export const FIELD_TYPE_LINK = "l";
export const FIELD_TYPE_STRING = "s";
export const FIELD_TYPE_CUSTOM = "c";

export const FIELD_TYPE_LABELS = Object.freeze({
  [FIELD_TYPE_FILE]: TYPE_FILE_LABEL,
  [FIELD_TYPE_LINK]: TYPE_LINK_LABEL,
  [FIELD_TYPE_STRING]: TYPE_STRING_LABEL,
  [FIELD_TYPE_CUSTOM]: TYPE_CUSTOM_LABEL,
});

/**
 * Статусы согласования достижения
 */
export const ACHIEVEMENT_NO_STATUS = ""; //На рассмотрении
export const ACHIEVEMENT_STATUS_USER_REJECTED = "r"; //Отклонено юзером
export const ACHIEVEMENT_STATUS_MODERATION = "m"; //На модерации
export const ACHIEVEMENT_STATUS_REJECTED = "d"; //Отклонена модератором
export const ACHIEVEMENT_STATUS_APPROVED = "+"; //Подтверждена модератором

/**
 * Социальные аккаунты
 */
export const SOCIAL_PROVIDERS_SLUGS = Object.freeze({
  vk: "vkid",
  vk_old: "vk-oauth2",
  github: "github",
  github_old: "github_legacy",
  stackoverflow: "stackoverflow",
  stepik: "stepik",
  leader: "leader",
  unti: "unti",
  telegram: "telegram",
  kaggle: "kaggle",
  gitlab: "gitlab",
  mos_id: "mos",
  yandex: "yandex",
});

export const SOCIAL_PROVIDERS = {
  [SOCIAL_PROVIDERS_SLUGS.vk_old]: {
    slug: SOCIAL_PROVIDERS_SLUGS.vk_old,
    title: "Вконтакте",
    icon: require("@/assets/images/oauth/brand-vk.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.vk]: {
    slug: SOCIAL_PROVIDERS_SLUGS.vk,
    title: "Вконтакте",
    icon: require("@/assets/images/oauth/brand-vk.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.github]: {
    slug: SOCIAL_PROVIDERS_SLUGS.github,
    title: "Github",
    icon: require("@/assets/images/oauth/brand-github.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.stackoverflow]: {
    slug: SOCIAL_PROVIDERS_SLUGS.stackoverflow,
    title: "Stack Overflow",
    icon: require("@/assets/images/oauth/brand-stackoverflow.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.github_old]: {
    slug: SOCIAL_PROVIDERS_SLUGS.github_old,
    title: "Github",
    icon: require("@/assets/images/oauth/brand-github.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.stepik]: {
    slug: SOCIAL_PROVIDERS_SLUGS.stepik,
    title: "Stepik",
    icon: require("@/assets/images/oauth/brand-stepik.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.leader]: {
    slug: SOCIAL_PROVIDERS_SLUGS.leader,
    title: "Leader ID",
    icon: require("@/assets/images/oauth/brand-leader-id.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.gitlab]: {
    slug: SOCIAL_PROVIDERS_SLUGS.gitlab,
    title: "GitLab",
    icon: require("@/assets/images/oauth/brand-gitlab.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.unti]: {
    slug: SOCIAL_PROVIDERS_SLUGS.unti,
    title: "Университет 2035",
    icon: require("@/assets/images/oauth/brand-unti.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.telegram]: {
    slug: SOCIAL_PROVIDERS_SLUGS.telegram,
    title: "Телеграм",
    icon: require("@/assets/images/oauth/brand-telegram.svg"),
  },
  [SOCIAL_PROVIDERS_SLUGS.kaggle]: {
    icon: require("@/assets/images/oauth/brand-kaggle.svg"),
    slug: SOCIAL_PROVIDERS_SLUGS.kaggle,
    title: "Kaggle",
  },
  [SOCIAL_PROVIDERS_SLUGS.mos_id]: {
    icon: require("@/assets/images/oauth/brand-mosru.svg"),
    slug: SOCIAL_PROVIDERS_SLUGS.mos_id,
    title: "Mos ID",
  },
  [SOCIAL_PROVIDERS_SLUGS.yandex]: {
    icon: require("@/assets/images/oauth/brand-yandex.svg"),
    slug: SOCIAL_PROVIDERS_SLUGS.yandex,
    title: "Яндекс ID",
  },
};

export const PROVIDERS_FOR_SYNC = Object.freeze({
  [SOCIAL_PROVIDERS_SLUGS.github]: true,
  [SOCIAL_PROVIDERS_SLUGS.gitlab]: true,
  [SOCIAL_PROVIDERS_SLUGS.stackoverflow]: true,
  [SOCIAL_PROVIDERS_SLUGS.stepik]: true,
  [SOCIAL_PROVIDERS_SLUGS.mos_id]: true,
  [SOCIAL_PROVIDERS_SLUGS.kaggle]: true,
});
// Максимальное количество для списка идшников
// источника доски
export const MAX_BOARD_ID_LIST = 500;

export const TASK_STATUS_COMPLETED = "completed";
export const TASK_STATUS_PENDING = "pending";
export const TASK_STATUS_RUNNING = "running";
export const TALENT_COMPETENCE_GROUP_CODE = "talent";

export const COMMON_SCOPE = "contact";
export const REQUESTS_SCOPE = "requests";
export const PROJECTS_SCOPE = "projects";
export const ACHIEVEMENTS_SCOPE = "achievements";
export const EDUCATION_SCOPE = "education";
export const ORGANIZATIONS_SCOPE = "organizations";
export const MENTORS_SCOPE = "mentors";
export const USER_CONTACTS_SCOPE = "communications";

export const CONTACTS_SCOPES = Object.freeze({
  [COMMON_SCOPE]: {
    value: COMMON_SCOPE,
    title: "Общие данные",
  },
  [USER_CONTACTS_SCOPE]: {
    value: USER_CONTACTS_SCOPE,
    title: "Контакты",
  },
  [REQUESTS_SCOPE]: {
    value: REQUESTS_SCOPE,
    title: "Заявки на мероприятия",
  },
  [PROJECTS_SCOPE]: {
    value: PROJECTS_SCOPE,
    title: "Проекты",
  },
  [ACHIEVEMENTS_SCOPE]: {
    value: ACHIEVEMENTS_SCOPE,
    title: "Достижения",
  },
  [EDUCATION_SCOPE]: {
    value: EDUCATION_SCOPE,
    title: "Образование",
  },
  [ORGANIZATIONS_SCOPE]: {
    value: ORGANIZATIONS_SCOPE,
    title: "Организации",
  },
  [MENTORS_SCOPE]: {
    value: MENTORS_SCOPE,
    title: "Наставники",
  },
});

export const ORG_COMMON_ROUTE = "organization";
export const ORG_ENTITY_ROUTE = "entity";
export const ORG_ADMINS_ROUTE = "admins";
export const ORG_GROUPS_ROUTE = "groups";
export const ORG_REQUESTS_ROUTE = "org_requests";
export const ORG_VENUES_ROUTE = "venues";
export const ORG_PARTICIPANTS_ROUTE = "participants";

export const ORGANIZATION_ROUTES = [
  {
    value: ORG_COMMON_ROUTE,
    title: "Общие данные",
  },
  {
    value: ORG_ENTITY_ROUTE,
    title: "Юридическое лицо",
  },
  {
    value: ORG_ADMINS_ROUTE,
    title: "Администраторы",
  },
  {
    value: ORG_GROUPS_ROUTE,
    title: "Кружки",
  },
  {
    value: ORG_REQUESTS_ROUTE,
    title: "Заявки кружков",
  },
  {
    value: ORG_VENUES_ROUTE,
    title: "Площадки",
  },
  {
    value: ORG_PARTICIPANTS_ROUTE,
    title: "Участники",
  },
];

export const SYNC_CHUNK_SIZE = 4;
